function Wine() {
    return (
        <div id="wine">
             <div className="mobile-address-topper">
                <p>18 BEDFORD AVE., GREENPOINT, BK, 11222 <br></br>
                718-383-5345 MON-SUN 8A-11P</p>
            </div>
            <h1 className ="FM-h1">Wine</h1>
            <div className="row1">
                <img src={require('../../menu_images/WINE_LIST_1.png')} alt='seasonal wine list pg 1'></img>
                <img src={require('../../menu_images/WINE_LIST_2.png')} alt='seasonal wine list pg 2'></img>
            </div>
            <div className="row2">
                <img src={require('../../menu_images/WINE_LIST_3.png')} alt='seasonal wine list pg 3'></img>
                <img src={require('../../menu_images/WINE_LIST_4.png')} alt='seasonal wine list pg 4'></img>
            </div>
            <div className="screen-reader">
                <h3>Sparkling</h3>
                    <p>Sparkling Chablis: $15/$60</p>
                    <p>Frizzante Rose: $15/$60</p>
                    <p>Champagne Zero: $60 (375 ML Bottle)</p>
                    <p>Champagne Traditional Extra Brut: $85</p>
                <h3>White</h3>
                    <p>Riesling Trocken: $15/$60</p>
                    <p>Pinot Blanc: $14/$56</p>
                    <p>Muscadet: $15/$60</p>
                    <p>Chardonnay: $16/$64</p>
                <h3>Red</h3>
                    <p>Zweigelt+: $15/$60</p>
                    <p>Etna Rosso: $18/$72</p>>
                    <p>Pinot Noir: $17/$68</p>
                    <p>Cabernet Franc: $16/$64</p>
                <h3>Skin-Contact</h3>
                    <p>Moschofilero: $16/$64</p>
                    {/* <p>Riesling: $18/$72</p> */}
                    <p>Pinot Gris + Riesling: $15/$60</p>
                <h3>Rose</h3>
                    <p>Saint Laurent Rose: $14/$60 (Liter)</p>
                    {/* <p>Anima Rose: $80</p> */}
            </div>
        </div>
    )
}

export default Wine;