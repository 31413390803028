function Brunch() {
    return (
        <div id="brunch">
             <div className="mobile-address-topper">
                <p>18 BEDFORD AVE., GREENPOINT, BK, 11222 <br></br>
                718-383-5345 MON-SUN 8A-11P</p>
            </div>
            <h1 className ="FM-h1">Brunch</h1>
            <div className="row1">
                <img src={require('../../menu_images/BRUNCH_1.png')} alt='seasonal brunch menu pg 1'></img>
                <img src={require('../../menu_images/BRUNCH_2.png')} alt='seasonal brunch menu pg 2'></img>
            </div>
            <div className="row2">
                <img src={require('../../menu_images/BRUNCH_3.png')} alt='seasonal brunch menu pg 3'></img>
                <img src={require('../../menu_images/BRUNCH_4.png')} alt='seasonal brunch menu pg 4'></img>
            </div>
            <div className="screen-reader">
                <h3>Starters</h3>
                    <p>Daily Market Oysters. Half Dozen: $18. Dozen: $36</p>
                    <p>Daily Soutp: $12</p>
                    <p>Fruit Salad: $12</p>
                    <p>Green Pea Falafel: $18</p>
                    <p>Housemade Ricotta: $18</p>
                    <p>Steamed Mussels: $28</p>
                <h3>Baked In-House</h3>
                    <p>Croissant: $4</p>
                    <p>Classic Aussie Roll: $10</p>
                    <p>Spicy Harissa Chicken Pie: $10</p>
                    <p>side of spiced ketchup +$1</p>
                <h3>Breakfast</h3>
                    <p>Avocado Toast: $16</p>
                    <p>Sage Scrambled Egg Sandwich: $13</p>
                    <p>Steel Cut Irish Oats: $13</p>
                    <p>Five Leaves Granola: $14</p>
                    <p>Wild Rice Bowl: $18</p>
                    <p>Ricotta Pancakes: $22</p>
                    <p>House-Cured Norwegian Salmon: $20</p>
                    <p>Moroccan Scramble: $18</p>
                    <p>Mushroom Toast: $18</p>
                    <p>Big Brekky: $21</p>
                <h3>Sides</h3>
                    <p>Avocado: $6</p>
                    <p>Pan Roasted Tomatoes: $6</p>
                    <p>Sauteed Kale: $6</p>
                    <p>Sauteed Mushrooms</p>
                    <p>Hash Browns: $6</p>
                    <p>Grilled Chorizo: $6</p>
                    <p>Baked Beans (made with pork): $6</p>
                    <p>Thick Cut Bacon: $6</p>
                    <p>2 Eggs Any Style: $6</p>
                    <p>Steak: $15</p>
                    <p>House-Cured Salmon: $12</p>
                    <p>Grilled Halloumi: $9</p>
                    <p>Fries: $7</p>
                    <p>Truffle Fries: $9</p>
                <h3>Sandwiches</h3>
                    <h4>all come with your chouse of sala or fries</h4>
                        <p>Spicy Chicken: $20 (choice of grilled or crispy)</p>
                        <p>B.L.A.T.: $19</p>
                        <p>French Dip: $20</p>
                        <p>Roasted Veggies: $19</p>
                        <p>Veggie Burger: $21</p>
                        <p>Five Leaves Burger: $24</p>
                        <p>Burger: $21</p>
                        <p>Burger Additions : $2 each : chedder, gruyerre, blue, truffle fries</p>
                <h3>Salad</h3>
                    <p>Market Greens: $13</p>
                    <p>Black Kale: $16</p>
                    {/* <p>Endive Salad: $17</p> */}
                    <p>Little Gem Wedge: $18</p>
                    <p>Add... Grilled Halloumi ($9), Marinated Chicken ($10), Grilled Shrimp ($12), Seared Salmon ($15), Grilled Steak ($15)</p>
                <h3>Dessert</h3>
                    <p>Sticky Date Pudding: $12</p>
                    <p>Rosewater Pavlova: $12</p>
                    {/* <p>Tres Leches: $14</p> */}
                    <p>Wine Poached Apple Strudel: $ 12</p>
                    <p>Affogato: $8</p>
                <h3>Coffee & Tea</h3>
                    <p>Espresso: $5</p>
                    <p>Americano: $5</p>
                    <p>Latte: $6</p>
                    <p>Flat White: $6</p>
                    <p>Cappuccino: $6</p>
                    <p>Cold Brew: $5</p>
                    <p>Mocha: $6</p>
                    <p>Turmeric Latte: $5</p>
                    <p>Hot Chocolate: $5</p>
                    <p>Bushwick Tea: $4</p>
            </div>
        </div>
    )
}

export default Brunch;