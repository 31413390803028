function Cocktail() {
    return (
        <div id="cocktail">
            <div className="mobile-address-topper">
                <p>18 BEDFORD AVE., GREENPOINT, BK, 11222 <br></br>
                718-383-5345 MON-SUN 8A-11P</p>
            </div>
            <h1 className ="FM-h1">Cocktail</h1>
            <img src={require('../../menu_images/COCKTAILS.png')} alt='seasonal cocktail menu'></img>
            <div className="screen-reader">
                <h3>Signature Cocktails: $15</h3>
                    <p>El Rey</p>
                    <p>Dirt Nap #4</p>
                    <p>5L Espresso Martini</p>
                    <p>Lifeboat</p>
                    <p>Caligula Collins</p>
                    <p>Puddle Jumper</p>
                    <p>Michelada Verde</p>
                    <p>Bear Mountain</p>
                <h3>Martinis</h3>
                    <p>Dirty or Dry?</p>
                <h3>Classic Cocktails</h3>
                    <p>Mimosa, bellini, kir royale, aperol spritz, bloody mary, 5L Michelada: $13</p>
                <h3>Canned Beer</h3>
                    <p>Cooper's Pale Ale: $7</p>
                    <p>Three's Brewing: $9</p>
                    <p>Juneshine Hard Kombucha: $9</p>
                    <p>Transmitter Brewing Sour: $10</p>
                    <p>Other Half IPA: $10</p>
                    <p>Athletic Brewing Co: $6</p>
                <h3>Draft Beer</h3>
                    <p>Pacifico: $6</p>
                    <p>Greenpoint IPA: $8</p>
                    <p>Shacksbury Arlo Cider: $10</p>
                    <p>Hitachino: $10</p>
                <h3>N/A Cocktails: $10</h3>
                    <p>Ghia Gershon</p>
                    <p>St Agrestis Phony Negroni or Amaro Falso</p>
                    <p>Waters of Life</p>
                    <p>5L Michelada Lite</p>
                <h3>Refreshing</h3>
                    <p>Mexican Coke/Diet Coke: $6</p>
                    <p>Bundaberg Ginger Beer: $6</p>
                    <p>Iced Tea: $5</p>
                    <p>Orange Juice: $6</p>
                    <p>Fresh-made Lemonade: $6</p>
                    <p>Arnold Palmer: $6</p>
                    <p>Saratoga Bottled Water: $8</p>
            </div>
        </div>
    )
}

export default Cocktail;